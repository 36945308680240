import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = { onsubmit: "return false" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { onsubmit: "return false" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wm_input = _resolveComponent("wm-input")!
  const _component_wm_table_form = _resolveComponent("wm-table-form")!
  const _component_wm_main = _resolveComponent("wm-main")!
  const _component_wm_button = _resolveComponent("wm-button")!
  const _component_wm_dialog = _resolveComponent("wm-dialog")!

  return (_openBlock(), _createBlock(_component_wm_dialog, {
    show: _ctx.form.show,
    "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.show) = $event)),
    title: _ctx.state.langs.passwd_title,
    width: "420px",
    bottom: "40px",
    onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.Close()))
  }, {
    bottom: _withCtx(() => [
      (!_ctx.form.is_vcode)
        ? (_openBlock(), _createBlock(_component_wm_button, {
            key: 0,
            effect: "plain",
            type: "primary",
            padding: "0 32px",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.submitPwd()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.state.langs.next), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.form.is_vcode)
        ? (_openBlock(), _createBlock(_component_wm_button, {
            key: 1,
            effect: "plain",
            type: "primary",
            padding: "0 32px",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.form.is_vcode=false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.state.langs.prev), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.form.is_vcode)
        ? (_openBlock(), _createBlock(_component_wm_button, {
            key: 2,
            effect: "dark",
            type: "primary",
            padding: "0 32px",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.submitPwd()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.state.langs.confirm), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_wm_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_wm_table_form, null, {
            default: _withCtx(() => [
              (!_ctx.form.is_vcode)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", null, [
                        _createVNode(_component_wm_input, {
                          value: _ctx.form.uname,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.uname) = $event)),
                          disabled: "",
                          maxlength: "32",
                          icon: "ui ui_user",
                          padding: "0 10px 0 40px"
                        }, null, 8, ["value"])
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", null, [
                        _createVNode(_component_wm_input, {
                          value: _ctx.form.vcode,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.vcode) = $event)),
                          placeholder: _ctx.state.langs.passwd_code_placeholder,
                          maxlength: "4",
                          icon: "ui ui_safety",
                          padding: "0 10px 0 40px",
                          text: _ctx.form.text,
                          onTextClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getVcode()))
                        }, null, 8, ["value", "placeholder", "text"])
                      ])
                    ])
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.state.langs.passwd_new), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("form", _hoisted_2, [
                          _createVNode(_component_wm_input, {
                            type: "password",
                            value: _ctx.form.passwd1,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.passwd1) = $event)),
                            maxlength: "16"
                          }, null, 8, ["value"])
                        ])
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.state.langs.passwd_confirm), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("form", _hoisted_4, [
                          _createVNode(_component_wm_input, {
                            type: "password",
                            value: _ctx.form.passwd2,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.passwd2) = $event)),
                            maxlength: "16"
                          }, null, 8, ["value"])
                        ])
                      ])
                    ])
                  ], 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show", "title"]))
}