import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = { colspan: "2" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { colspan: "2" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { colspan: "2" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { colspan: "2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wm_input = _resolveComponent("wm-input")!
  const _component_wm_radio = _resolveComponent("wm-radio")!
  const _component_wm_date_picker = _resolveComponent("wm-date-picker")!
  const _component_wm_table_form = _resolveComponent("wm-table-form")!
  const _component_wm_main = _resolveComponent("wm-main")!
  const _component_wm_button = _resolveComponent("wm-button")!
  const _component_wm_dialog = _resolveComponent("wm-dialog")!

  return (_openBlock(), _createBlock(_component_wm_dialog, {
    show: _ctx.uinfoShow,
    "onUpdate:show": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.uinfoShow) = $event)),
    title: _ctx.langs.uinfo_title,
    width: "560px",
    bottom: "40px",
    onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.Close()))
  }, {
    bottom: _withCtx(() => [
      _createVNode(_component_wm_button, {
        effect: "dark",
        type: "primary",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.subUinfo()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.langs.confirm), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_wm_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_wm_table_form, null, {
            default: _withCtx(() => [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.langs.uinfo_nickname), 1),
                _createElementVNode("td", _hoisted_2, [
                  _createVNode(_component_wm_input, {
                    value: _ctx.form.nickname,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.nickname) = $event)),
                    placeholder: _ctx.langs.uinfo_nickname_placeholder,
                    maxlength: "16"
                  }, null, 8, ["value", "placeholder"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.langs.uinfo_name), 1),
                _createElementVNode("td", null, [
                  _createVNode(_component_wm_input, {
                    value: _ctx.form.name,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event)),
                    maxlength: "16",
                    disabled: ""
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_wm_radio, {
                    value: _ctx.form.gender,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.gender) = $event)),
                    options: _ctx.genderData
                  }, null, 8, ["value", "options"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.langs.uinfo_birthday), 1),
                _createElementVNode("td", _hoisted_5, [
                  _createVNode(_component_wm_date_picker, {
                    value: _ctx.form.birthday,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.birthday) = $event)),
                    maxDate: _ctx.maxDate
                  }, null, 8, ["value", "maxDate"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.langs.uinfo_department), 1),
                _createElementVNode("td", _hoisted_7, [
                  _createVNode(_component_wm_input, {
                    value: _ctx.form.department,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.department) = $event)),
                    maxlength: "16"
                  }, null, 8, ["value"])
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.langs.uinfo_position), 1),
                _createElementVNode("td", _hoisted_9, [
                  _createVNode(_component_wm_input, {
                    value: _ctx.form.position,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.position) = $event)),
                    maxlength: "16"
                  }, null, 8, ["value"])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show", "title"]))
}