import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = { class: "app_main flex" }
const _hoisted_2 = { class: "menus_body flex" }
const _hoisted_3 = { class: "menus_left" }
const _hoisted_4 = { class: "m1" }
const _hoisted_5 = {
  key: 0,
  class: "m2"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "null"
}
const _hoisted_8 = { class: "m1" }
const _hoisted_9 = { class: "m2" }
const _hoisted_10 = { style: {paddingLeft:''} }
const _hoisted_11 = { style: {paddingLeft:''} }
const _hoisted_12 = { class: "menus_right" }
const _hoisted_13 = { class: "m_top flex" }
const _hoisted_14 = { class: "search" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "menus_ct scrollbar" }
const _hoisted_17 = {
  key: 0,
  class: "null center"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "title" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "app_logo flex" }
const _hoisted_23 = {
  key: 0,
  class: "logo_text"
}
const _hoisted_24 = {
  key: 0,
  class: "app_search"
}
const _hoisted_25 = ["placeholder"]
const _hoisted_26 = {
  key: 0,
  class: "null center"
}
const _hoisted_27 = {
  key: 1,
  class: "m2"
}
const _hoisted_28 = ["onClick"]
const _hoisted_29 = {
  key: 0,
  class: "m"
}
const _hoisted_30 = ["title"]
const _hoisted_31 = ["onClick"]
const _hoisted_32 = {
  key: 0,
  class: "m2"
}
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { class: "app_copy" }
const _hoisted_35 = { class: "app_user_info scrollbar" }
const _hoisted_36 = { class: "img_load" }
const _hoisted_37 = {
  key: 0,
  class: "ui ui_image"
}
const _hoisted_38 = { class: "user" }
const _hoisted_39 = { class: "info" }
const _hoisted_40 = { class: "flex" }
const _hoisted_41 = { class: "name" }
const _hoisted_42 = { class: "value" }
const _hoisted_43 = { class: "flex" }
const _hoisted_44 = { class: "name" }
const _hoisted_45 = { class: "value" }
const _hoisted_46 = { class: "flex" }
const _hoisted_47 = { class: "name" }
const _hoisted_48 = { class: "value" }
const _hoisted_49 = { class: "flex" }
const _hoisted_50 = { class: "name" }
const _hoisted_51 = { class: "value" }
const _hoisted_52 = { class: "tools" }
const _hoisted_53 = { class: "app_tabs_body flex" }
const _hoisted_54 = { class: "app_tabs flex_left" }
const _hoisted_55 = ["onClick"]
const _hoisted_56 = ["onClick"]
const _hoisted_57 = { class: "app_tools flex" }
const _hoisted_58 = {
  key: 0,
  class: "redNum"
}
const _hoisted_59 = {
  key: 0,
  class: "ui ui_user"
}
const _hoisted_60 = { class: "app_body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login = _resolveComponent("login")!
  const _component_uinfo = _resolveComponent("uinfo")!
  const _component_passwd = _resolveComponent("passwd")!
  const _component_msg = _resolveComponent("msg")!
  const _component_wm_popup = _resolveComponent("wm-popup")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_login, {
      ref: "Login",
      show: _ctx.state.isLogin,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.isLogin) = $event))
    }, null, 8, ["show"]),
    (_ctx.state.isLogin)
      ? (_openBlock(), _createBlock(_component_uinfo, {
          key: 0,
          show: _ctx.state.isUinfo,
          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.isUinfo) = $event))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    (_ctx.state.isLogin)
      ? (_openBlock(), _createBlock(_component_passwd, {
          key: 1,
          show: _ctx.state.isPasswd,
          "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.isPasswd) = $event))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    (_ctx.state.isLogin)
      ? (_openBlock(), _createBlock(_component_msg, {
          key: 2,
          show: _ctx.msg.show,
          "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.msg.show) = $event))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "app_menus_all",
        style: _normalizeStyle({visibility:_ctx.menus.show&&_ctx.state.isLogin?'inherit':'hidden'})
      }, [
        _createVNode(_component_wm_popup, {
          height: "100%",
          width: "900px",
          show: _ctx.menus.show,
          "onUpdate:show": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.menus.show) = $event)),
          position: "left",
          bgColor: "#FFF",
          time: 600
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.state.langs.menus_recent), 1),
                (_ctx.menus.tmpList.length>0)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus.tmpList, (v, k) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: "flex_left",
                          key: k,
                          onClick: ($event: any) => (_ctx.MenusClick(v.label, v.url, true))
                        }, [
                          (v.icon)
                            ? (_openBlock(), _createElementBlock("i", {
                                key: 0,
                                class: _normalizeClass(v.icon)
                              }, null, 2))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", {
                            style: _normalizeStyle({paddingLeft:v.icon?'':'8px'})
                          }, _toDisplayString(v.label), 5)
                        ], 8, _hoisted_6))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7)),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.state.langs.menus_recommend), 1),
                _createElementVNode("ul", _hoisted_9, [
                  _createElementVNode("li", {
                    class: "flex_left",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.state.isUinfo=true))
                  }, [
                    _cache[23] || (_cache[23] = _createElementVNode("i", { class: "ui ui_user" }, null, -1)),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.state.langs.uinfo_title), 1)
                  ]),
                  _createElementVNode("li", {
                    class: "flex_left",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.state.isPasswd=true))
                  }, [
                    _cache[24] || (_cache[24] = _createElementVNode("i", { class: "ui ui_safety" }, null, -1)),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.state.langs.passwd_title), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[25] || (_cache[25] = _createElementVNode("i", { class: "ui ui_search" }, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "input",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.menus.key) = $event)),
                      onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.MenusSearch())),
                      placeholder: _ctx.state.langs.menus_placeholder
                    }, null, 40, _hoisted_15), [
                      [_vModelText, _ctx.menus.key]
                    ])
                  ]),
                  _createElementVNode("i", {
                    class: "ui ui_close",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.menus.show=false))
                  })
                ]),
                _createElementVNode("div", _hoisted_16, [
                  (_ctx.menus.key&&_ctx.menus.seaList.length==0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17))
                    : (_openBlock(), _createElementBlock("ul", _hoisted_18, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus.list, (v1, k1) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: k1 }, [
                            (v1.children)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(v1.children, (v2, k2) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, { key: k2 }, [
                                    (v2.children&&(v2.display||typeof v2.display=='undefined'))
                                      ? (_openBlock(), _createElementBlock("li", _hoisted_19, [
                                          _createElementVNode("div", _hoisted_20, _toDisplayString(v2.langs[_ctx.state.lang]), 1),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v2.children, (v3, k3) => {
                                            return (_openBlock(), _createElementBlock(_Fragment, { key: k3 }, [
                                              (v3.display||typeof v3.display=='undefined')
                                                ? (_openBlock(), _createElementBlock("div", {
                                                    key: 0,
                                                    class: _normalizeClass(["m flex_left", _ctx.tabs.active==v3.value.url?'active':'']),
                                                    onClick: ($event: any) => (_ctx.MenusClick(v3.langs[_ctx.state.lang], v3.value.url, true))
                                                  }, [
                                                    (v3.icon)
                                                      ? (_openBlock(), _createElementBlock("i", {
                                                          key: 0,
                                                          class: _normalizeClass(v3.icon)
                                                        }, null, 2))
                                                      : _createCommentVNode("", true),
                                                    _createElementVNode("span", {
                                                      style: _normalizeStyle({paddingLeft: v3.icon?'':'10px'})
                                                    }, _toDisplayString(v3.langs[_ctx.state.lang]), 5)
                                                  ], 10, _hoisted_21))
                                                : _createCommentVNode("", true)
                                            ], 64))
                                          }), 128))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                }), 128))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ]))
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["show"])
      ], 4),
      _createElementVNode("div", {
        class: "app_left",
        style: _normalizeStyle({width: _ctx.is_menus?'240px':'56px'})
      }, [
        _createElementVNode("div", {
          class: "app_on",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.MenusShow()))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["ui", _ctx.is_menus?'ui_arrow_left':'ui_arrow_right'])
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", {
            class: "logo",
            onClick: _cache[11] || (_cache[11] = ($event: any) => {_ctx.menus.show=!_ctx.menus.show;_ctx.uinfo.show=false}),
            style: _normalizeStyle({backgroundImage:'url('+require('./assets/logo.svg')+')'})
          }, null, 4),
          (_ctx.is_menus)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, "WebMIS 3.0"))
            : _createCommentVNode("", true)
        ]),
        (_ctx.is_menus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _cache[26] || (_cache[26] = _createElementVNode("i", { class: "ui ui_search" }, null, -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.menus.key) = $event)),
                onInput: _cache[13] || (_cache[13] = ($event: any) => (_ctx.MenusSearch())),
                placeholder: _ctx.state.langs.menus_placeholder
              }, null, 40, _hoisted_25), [
                [_vModelText, _ctx.menus.key]
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "app_menus scrollbar",
          style: _normalizeStyle({height: 'calc(100% - '+(_ctx.is_menus?'152px':'98px')+')',})
        }, [
          (_ctx.menus.key&&_ctx.menus.seaList.length==0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26))
            : (_ctx.menus.key&&_ctx.menus.seaList.length>0&&_ctx.is_menus)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_27, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus.seaList, (v, k) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: _normalizeClass(["flex_left", _ctx.tabs.active==v.url?'active':'']),
                      key: k,
                      onClick: ($event: any) => (_ctx.MenusClick(v.label, v.url))
                    }, [
                      (v.icon)
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: _normalizeClass(v.icon)
                          }, null, 2))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", {
                        style: _normalizeStyle({paddingLeft: v.icon?'':'16px'})
                      }, _toDisplayString(v.label), 5)
                    ], 10, _hoisted_28))
                  }), 128))
                ]))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.menus.list, (v1, k1) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: k1 }, [
                    (!_ctx.is_menus)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                          _createElementVNode("i", {
                            class: _normalizeClass(v1.icon),
                            title: v1.langs[_ctx.state.lang],
                            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.MenusShow()))
                          }, null, 10, _hoisted_30)
                        ]))
                      : _createCommentVNode("", true),
                    (v1.children&&_ctx.is_menus)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(v1.children, (v2, k2) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: k2 }, [
                            _createElementVNode("div", {
                              class: "m1 flex",
                              onClick: ($event: any) => (v2.show=!v2.show)
                            }, [
                              _createElementVNode("span", null, _toDisplayString(v2.langs[_ctx.state.lang]), 1),
                              _createElementVNode("i", {
                                class: "ui ui_arrow_up",
                                style: _normalizeStyle({transform: v2.show?'rotate(0deg)':'rotate(180deg)'})
                              }, null, 4)
                            ], 8, _hoisted_31),
                            (v2.children&&v2.show)
                              ? (_openBlock(), _createElementBlock("ul", _hoisted_32, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v2.children, (v3, k3) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                      class: _normalizeClass(["flex_left", _ctx.tabs.active==v3.value.url?'active':'']),
                                      key: k3,
                                      onClick: ($event: any) => (_ctx.MenusClick(v3.langs[_ctx.state.lang], v3.value.url))
                                    }, [
                                      (v3.icon)
                                        ? (_openBlock(), _createElementBlock("i", {
                                            key: 0,
                                            class: _normalizeClass(v3.icon)
                                          }, null, 2))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", {
                                        style: _normalizeStyle({paddingLeft: v3.icon?'':'16px'})
                                      }, _toDisplayString(v3.langs[_ctx.state.lang]), 5)
                                    ], 10, _hoisted_33))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
        ], 4),
        _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.copy), 1)
      ], 4),
      _createElementVNode("div", {
        class: "app_right",
        style: _normalizeStyle({width: _ctx.is_menus?'calc(100% - 240px)':'calc(100% - 56px)'})
      }, [
        _createElementVNode("div", {
          class: "app_user_info_body",
          style: _normalizeStyle({visibility:_ctx.uinfo.show&&_ctx.state.isLogin?'inherit':'hidden'})
        }, [
          _createVNode(_component_wm_popup, {
            height: "100%",
            width: "320px",
            show: _ctx.uinfo.show,
            "onUpdate:show": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.uinfo.show) = $event)),
            position: "right",
            bgColor: "#FFF"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", {
                  class: "img",
                  style: _normalizeStyle({backgroundImage: _ctx.state.uinfo.img?'url('+_ctx.state.uinfo.img+')':'none'}),
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.userUpImg()))
                }, [
                  _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.state.langs.uinfo_img), 1),
                  (!_ctx.state.uinfo.img)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_37))
                    : _createCommentVNode("", true)
                ], 4),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.state.uinfo.uname || '-'), 1),
                  _createElementVNode("p", null, "ID: " + _toDisplayString(_ctx.state.uinfo.uid || '-'), 1)
                ]),
                _createElementVNode("ul", _hoisted_39, [
                  _createElementVNode("li", _hoisted_40, [
                    _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.state.langs.uinfo_nickname), 1),
                    _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.state.uinfo.nickname || '-'), 1)
                  ]),
                  _createElementVNode("li", _hoisted_43, [
                    _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.state.langs.uinfo_department), 1),
                    _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.state.uinfo.department || '-'), 1)
                  ]),
                  _createElementVNode("li", _hoisted_46, [
                    _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.state.langs.uinfo_position), 1),
                    _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.state.uinfo.position || '-'), 1)
                  ]),
                  _createElementVNode("li", _hoisted_49, [
                    _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.state.langs.uinfo_name), 1),
                    _createElementVNode("span", _hoisted_51, _toDisplayString(_ctx.state.uinfo.name || '-'), 1)
                  ])
                ]),
                _createElementVNode("ul", _hoisted_52, [
                  _createElementVNode("li", {
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.state.isUinfo=true))
                  }, _toDisplayString(_ctx.state.langs.uinfo_title), 1),
                  _createElementVNode("li", {
                    onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.state.isPasswd=true))
                  }, _toDisplayString(_ctx.state.langs.passwd_title), 1)
                ])
              ]),
              _createElementVNode("div", {
                class: "app_user_close",
                onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.logout()))
              }, _toDisplayString(_ctx.state.langs.logout), 1)
            ]),
            _: 1
          }, 8, ["show"])
        ], 4),
        _createElementVNode("div", _hoisted_53, [
          _createElementVNode("ul", _hoisted_54, [
            _createElementVNode("li", {
              class: _normalizeClass(["home", _ctx.tabs.active=='/'?'active':'']),
              onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.MenusClick(_ctx.state.langs.home, '/')))
            }, _toDisplayString(_ctx.state.langs.home), 3),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs.list, (v, k) => {
              return (_openBlock(), _createElementBlock("li", {
                key: k,
                onClick: ($event: any) => (_ctx.MenusClick(v.name, v.url)),
                class: _normalizeClass(_ctx.tabs.active==v.url?'active':'')
              }, [
                _createTextVNode(_toDisplayString(v.name), 1),
                _createElementVNode("span", {
                  class: "close",
                  onClick: _withModifiers(($event: any) => (_ctx.MenusClose(v.url)), ["stop"])
                }, _cache[27] || (_cache[27] = [
                  _createElementVNode("i", { class: "ui ui_close" }, null, -1)
                ]), 8, _hoisted_56)
              ], 10, _hoisted_55))
            }), 128))
          ]),
          _createElementVNode("ul", _hoisted_57, [
            _createElementVNode("li", {
              class: "msg",
              title: "消息",
              onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.msg.show=true))
            }, [
              (_ctx.state.msg.num>0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_58, _toDisplayString(_ctx.state.msg.num), 1))
                : _createCommentVNode("", true),
              _cache[28] || (_cache[28] = _createElementVNode("i", { class: "ui ui_message" }, null, -1))
            ]),
            _createElementVNode("li", {
              class: "user",
              title: "用户信息",
              onClick: _cache[22] || (_cache[22] = ($event: any) => {_ctx.uinfo.show=!_ctx.uinfo.show;_ctx.menus.show=false})
            }, [
              (!_ctx.state.uinfo.img || _ctx.uinfo.show)
                ? (_openBlock(), _createElementBlock("i", _hoisted_59))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "img",
                    style: _normalizeStyle({backgroundImage: 'url('+_ctx.state.uinfo.img+')'})
                  }, null, 4))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_60, [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: _ctx.$route.name
                }))
              ], 1024))
            ]),
            _: 1
          })
        ])
      ], 4)
    ])
  ], 64))
}